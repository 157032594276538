<template>
  <Layout tituloPagina="Sistema | Cuenta | Pago | Fallido">
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Pago rechazado
            </h4>
          </div>
          <div class="card-body">
            <p>
              El pago presentado fue rechazado por el proceso o el banco, o no se puede utilizar para realizar el pago correspondiente.
            </p>
            <p>
              Da click en el siguiente botón para regresar al panel de cuenta.
            </p>
          </div>
        </div>
        <div class="text-right">
          <router-link class="btn btn-success" :to="{ name: 'sistemaCuenta' }">
            Cuenta
          </router-link>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"

export default {
  name: 'Fallido',
  components: { Layout },
  data() {
    return {
    }
  },

  created: function() {
  }
}
</script>

<style>
</style>